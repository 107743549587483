import { applyTransaction, arrayAdd, arrayRemove, arrayUpdate, combineQueries, filterNilValue, getEntityStoreByName } from '@datorama/akita';
import { pick } from 'lodash-es';
import { map } from 'rxjs';
import approvalCenterStore from 'state/ApprovalCenter/store';
import CRUDService from 'state/CRUDService';
import { query as kpisQuery } from 'state/KPI/query';
import kpisStore from 'state/KPI/store';
import { query as kpiFieldsQuery } from 'state/KPIField/query';
import KPISubsService from 'state/KPISub/service';
import kpiSubsStore from 'state/KPISub/store';
import PeriodsService from 'state/Period/service';
import { activeReportId$ } from 'state/ReportDocument/query';
import { query as uiQuery } from 'state/UI/query';
import { fieldTypeIsNumeric } from 'utils';
import { ApprovalStatus, KPIValueType } from 'utils/enum';
import { query } from './query';
import store from './store';

export default class KPIValuesService extends CRUDService {
   constructor() {
      if (!KPIValuesService.instance) {
         super('kpivalues', store, query, ['versions'], true, true, true, false, true);
         this.periodsService = new PeriodsService();
         this.kpiSubsService = new KPISubsService();
         this.kpiFieldsStore = getEntityStoreByName('kpiFields');
         this.reportController = new AbortController();
         this.reportDataController = new AbortController();
         this.approvalCenterStore = approvalCenterStore;
         this.pendingReportRequest = new Set();
         this.pendingReportDataRequest = new Set();
         this.store.setLoading(false);
         KPIValuesService.instance = this;
      }

      // KPIValuesService shall be instantiated only once, because otherwise the observable will be created for each service instance

      return KPIValuesService.instance;
   }

   async createEntity(entity, fetchAssociations = false, updateStore = true) {
      const newEntitiesReturn = await super.createEntity(entity, fetchAssociations, undefined, false);

      const newEntities = [newEntitiesReturn].flat();

      if (newEntities.length > 0 && updateStore) {
         applyTransaction(() => {
            newEntities.forEach((newEntity) => {
               if (newEntity?.kpiFieldId) {
                  if ([KPIValueType.DECIMAL, KPIValueType.INTEGER].includes(newEntity?.dataType)) {
                     this.kpiFieldsStore.update(entity.kpiFieldId, {
                        kpiValuesSum: newEntity.value,
                     });
                  }

                  this.kpiFieldsStore.update(newEntity.kpiFieldId, {
                     kpiValueId: newEntity.id,
                     kpiValuesCount: 1,
                     justification: newEntity.justification,
                     value: newEntity.value,
                     values: [newEntity],
                     status: ApprovalStatus.IN_PROGRESS,
                     hasValueItems: newEntity.hasValueItems ?? false,
                     isEstimated: newEntity.isEstimated,
                  });
               }
            });

            this.refreshCurrentKPIProgress();
         });

         const kpiFieldId = newEntities?.[0]?.kpiFieldId;

         if (kpiFieldId) {
            const kpiField = kpiFieldsQuery.getEntity(kpiFieldId);

            if (fieldTypeIsNumeric(kpiField.type)) {
               await this.kpiSubsService.getFieldSums(kpiField.kpiContent.kpiSub.id, true);
            }
         }
      }

      return newEntitiesReturn;
   }

   async createValueItem(kpiFieldId, values, kpiValueId = undefined, updateStore = true) {
      const collectionName = 'items';

      const urlParams = new URLSearchParams();
      urlParams.set('kpiFieldId', kpiFieldId);

      return this.httpClient
         .post(`/${this.version}/kpivalueitems?${urlParams}`, values)
         .then((resp) => {
            if (updateStore) {
               if (!kpiValueId) {
                  this.store.upsertMany(resp.data);
               } else {
                  this.store.update(kpiValueId, ({ [collectionName]: collectionName_ }) => ({
                     [collectionName]: arrayAdd(collectionName_, resp.data[0]?.items),
                  }));
               }
            }

            return resp.data[0]?.items ?? [];
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async updateValueItem(kpiValueItemId, kpiValueId, changes) {
      const collectionName = 'items';

      return this.httpClient
         .patch(`/${this.version}/kpivalueitems/${kpiValueItemId}`, changes)
         .then((resp) => {
            this.store.update(kpiValueId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayUpdate(collectionName_, kpiValueItemId, resp.data),
            }));

            return resp.data?.items ?? [];
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteValueItems(valueIdToValueItemIdsMapping) {
      const collectionName = 'items';

      return this.httpClient
         .delete(`/${this.version}/kpivalueitems`, { data: Object.values(valueIdToValueItemIdsMapping).flat() })
         .then(() => {
            applyTransaction(() => {
               Object.entries(valueIdToValueItemIdsMapping).forEach(([kpiValueId, kpiValueItemIds]) => {
                  this.store.update(kpiValueId, ({ [collectionName]: collectionName_ }) => ({
                     [collectionName]: arrayRemove(collectionName_, kpiValueItemIds),
                  }));

                  const kpiValue = this.query.getEntity(kpiValueId);

                  if (kpiValue.items.length === 0) {
                     this.store.remove(kpiValueId);
                  }
               });
            });

            return true;
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteValueItem(kpiValueItemId, kpiValueId) {
      const collectionName = 'items';

      return this.httpClient
         .delete(`/${this.version}/kpivalueitems/${kpiValueItemId}`)
         .then(() => {
            this.store.update(kpiValueId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayRemove(collectionName_, kpiValueItemId),
            }));

            const kpiValue = this.query.getEntity(kpiValueId);

            if (kpiValue.items.length === 0) {
               this.store.remove(kpiValueId);
            }

            return true;
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async updateEntity(entityId, kpiFieldId, changes) {
      let updateResult;

      if (kpiFieldId) {
         const kpiField = kpiFieldsQuery.getEntity(kpiFieldId);

         updateResult = await super.updateEntity(entityId, changes);

         if (fieldTypeIsNumeric(kpiField.type) || kpiField?.kpiContent?.kpiSub?.id) {
            await this.kpiSubsService.getFieldSums(kpiField.kpiContent.kpiSub.id, true);
         }
         this.refreshCurrentKPIProgress();
      } else {
         updateResult = await super.updateEntity(entityId, changes);
      }

      return updateResult;
   }

   async deleteEntities(entityIdArray) {
      return this.httpClient
         .delete(`/${this.version}/${this.entityName}`, { data: entityIdArray })
         .then(() => this.store.remove(entityIdArray))
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteEntity(entityId) {
      const result = await super.deleteEntity(entityId);

      const kpiValue = this.query.getEntity(entityId);

      if (kpiValue?.kpiFieldId) {
         const kpiField = kpiFieldsQuery.getEntity(kpiValue.kpiFieldId);

         if (fieldTypeIsNumeric(kpiField.type) || kpiField?.kpiContent?.kpiSub?.id) {
            await this.kpiSubsService.getFieldSums(kpiField.kpiContent.kpiSub.id, true);
         }
      }

      this.refreshCurrentKPIProgress();

      return result;
   }

   async refreshCurrentKPIProgress() {
      const activeKPIId = kpisQuery.getActiveId();

      const promises = [];

      if (activeKPIId) {
         const includeSubsidiaries = this.userContextQuery.getValue().includeSubsidiaries;

         const queryParams = new URLSearchParams();
         queryParams.set('includeSubsidiaries', includeSubsidiaries ?? false);

         promises.push(
            this.httpClient
               .get(`/${this.version}/kpis/${activeKPIId}/progress?${queryParams.toString()}`)
               .then((resp) =>
                  kpisStore.update(
                     activeKPIId,
                     pick(resp.data, ['approved', 'filled', 'numAssignedTasks', 'numComments', 'numTasks', 'earliestDueDate'])
                  )
               )
               .catch((error) => {
                  this.setError(error);
               })
         );

         promises.push(
            this.httpClient
               .get(`/${this.version}/kpis/${activeKPIId}/subs?${queryParams.toString()}`)
               .then((resp) =>
                  kpiSubsStore.upsertMany(
                     resp.data.map((kpiSubInfo) =>
                        pick(kpiSubInfo, ['id', 'approved', 'filled', 'numAssignedTasks', 'numComments', 'numTasks', 'earliestDueDate'])
                     )
                  )
               )
               .catch((error) => {
                  this.setError(error);
               })
         );
      }

      if (!activeKPIId) {
         return Promise.resolve(false);
      }
      return Promise.all(promises);
   }

   getSums() {
      if (!this.reportObservable || this.reportObservable?.closed) {
         this.reportObservable = combineQueries([
            this.queryParamsObservable,
            uiQuery.select(['keyFigureReportReportingStandardIds', 'reportOnlyApproved', 'reportGroupByOrganisation', 'keyFigureTagIds']),
         ])
            .pipe(
               map(([queryParams, uiParams]) => {
                  const { keyFigureReportReportingStandardIds, reportOnlyApproved, reportGroupByOrganisation, keyFigureTagIds } = uiParams;

                  if (keyFigureReportReportingStandardIds?.length > 0) {
                     this.store.setLoading(true);

                     const params = new URLSearchParams(queryParams);

                     const includeSubsidiaries = params.get('includeSubsidiaries') === 'true';

                     params.set('onlyNumeric', false);
                     params.set('onlyApproved', reportOnlyApproved);
                     params.set('aggregate', true);
                     params.set('aggregationLevel', includeSubsidiaries && reportGroupByOrganisation ? 'entity' : 'group');
                     params.set('scope', true);
                     params.set('reportingStandardIds', keyFigureReportReportingStandardIds.join(','));
                     params.set('type', 'kpi');

                     if (keyFigureTagIds?.length > 0) {
                        params.set('tag', keyFigureTagIds.join(','));
                     }

                     const queryString = params.toString();

                     if (this.pendingReportRequest.has(queryString)) {
                        return;
                     }

                     if (this.pendingReportRequest.size > 0 && !this.pendingReportRequest.has(queryString)) {
                        this.reportController.abort();
                        this.reportController = new AbortController();
                        this.pendingReportRequest.clear();
                     }

                     this.pendingReportRequest.add(queryString);

                     return this.httpClient
                        .get(`/${this.version}/${this.entityName}?${params.toString()}`, {
                           signal: this.reportController.signal,
                        })
                        .then((resp) =>
                           applyTransaction(() => {
                              this.store.update({ sums: resp.data });
                              this.store.setLoading(false);
                           })
                        )
                        .catch((error) => {
                           this.setError(error);
                        })
                        .finally(() => this.pendingReportRequest.delete(queryString));
                  }

                  return undefined;
               })
            )
            .subscribe();
      }
   }

   getReportData() {
      if (!this.reportDataObservable || this.reportDataObservable?.closed) {
         this.reportDataObservable = activeReportId$
            .pipe(
               filterNilValue(),
               map((reportId) => {
                  this.store.setLoading(true);

                  const params = new URLSearchParams();

                  params.set('onlyNumeric', false);
                  params.set('onlyApproved', false);
                  params.set('aggregate', true);
                  params.set('aggregationLevel', 'group');
                  params.set('scope', true);
                  params.set('reportId', reportId);
                  params.set('type', 'kpi');

                  const queryString = params.toString();

                  if (this.pendingReportDataRequest.has(queryString)) {
                     return;
                  }

                  if (this.pendingReportDataRequest.size > 0 && !this.pendingReportDataRequest.has(queryString)) {
                     this.reportDataController.abort();
                     this.reportDataController = new AbortController();
                     this.pendingReportDataRequest.clear();
                  }

                  this.pendingReportDataRequest.add(queryString);

                  return this.httpClient
                     .get(`/${this.version}/${this.entityName}?${params.toString()}`, {
                        signal: this.reportDataController.signal,
                     })
                     .then((resp) =>
                        applyTransaction(() => {
                           this.store.update({ reportData: resp.data });
                           this.store.setLoading(false);
                        })
                     )
                     .catch((error) => {
                        this.setError(error);
                     })
                     .finally(() => this.pendingReportDataRequest.delete(queryString));
               })
            )
            .subscribe();
      }
   }

   async uploadFilesForItem(fieldId, kpiValueItemId, files, organisationId, from, to, fieldType) {
      const attachmentPromises = [];
      Object.entries(files).forEach(([, file]) => {
         const formData = new FormData();
         formData.append('attachment', file);
         formData.append('organisationId', organisationId);
         formData.append('from', from);
         formData.append('to', to);
         formData.append('fieldType', fieldType);

         attachmentPromises.push(
            this.httpClient
               .post(`/${this.version}/${this.entityName}/${fieldId}/items/${kpiValueItemId}/attachments`, formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
               })
               .then((resp) => resp.data)
               .catch((error) => {
                  this.setError(error);
               })
         );
      });

      const promiseResults = await Promise.all(attachmentPromises);

      return promiseResults.flat();
   }

   async deleteItemAttachment(kpiFieldId, kpiValueItemId, kpiValueItemAttachmentId, fieldType) {
      const urlParams = new URLSearchParams();
      urlParams.set('fieldType', fieldType);
      return this.httpClient
         .delete(`/${this.version}/${this.entityName}/${kpiFieldId}/items/${kpiValueItemId}/attachments/${kpiValueItemAttachmentId}?${urlParams}`)
         .then((resp) => resp.data)
         .catch((error) => {
            this.setError(error);
         });
   }

   async setStatusApprovalCenter(setStatusValues, auditValues, kind) {
      return this.approvalCenterStore.update((entity) => (auditValues ?? []).includes(entity?.id) && entity?.kind === kind, {
         ...setStatusValues,
      });
   }

   unsubscribeReport() {
      if (this.reportController) {
         // this.reportController.abort();
      }

      // this.pendingReportRequest.clear();

      if (this.reportObservable && !this.reportObservable.closed) {
         this.reportObservable.unsubscribe();
      }
   }

   unsubscribeReportData() {
      if (this.reportDataController) {
         // this.reportDataController.abort();
      }

      // this.pendingReportDataRequest.clear();

      if (this.reportDataObservable && !this.reportDataObservable.closed) {
         this.reportDataObservable.unsubscribe();
      }
   }
}
