import { combineQueries, createQuery, filterNilValue } from '@datorama/akita';
import userContextStore from 'context/UserContext/store';
import { flatMapDeep, isEqual, pick, uniq } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { entities$ as organisations$ } from 'state/Organisations/query';

export function getParentIds(currentOrganisationId, organisations) {
   const currentOrganisation = organisations.find(({ id }) => id === currentOrganisationId);

   if (currentOrganisation?.parents?.length > 0) {
      return (
         currentOrganisation.parents
            // .filter(({ validFrom, validTo }) => (!validFrom || parseISO(validFrom) <= parseISO(from)) && (!validTo || parseISO(validTo) <= parseISO(to)))
            .reduce((parentIds, parent) => uniq([...parentIds, parent.id, ...getParentIds(parent.id, organisations)]), [])
      );
   }

   return [];
}

export function getSubsidiaryIds(currentOrganisationId, organisations) {
   const subsidiaries = organisations.filter(({ parents }) => (parents ?? []).some(({ id }) => id === currentOrganisationId));

   return uniq([...subsidiaries.map(({ id }) => id), ...subsidiaries.flatMap(({ id }) => getSubsidiaryIds(id, organisations))]);
}

export const organisationContextQuery = createQuery(userContextStore);

export const context$ = organisationContextQuery.select('activeOrganisation');

export const activeOrganisationId$ = organisationContextQuery.select('activeOrganisationId');
export const organisationId$ = organisationContextQuery.select('organisationId');

export const reportingStandards$ = context$.pipe(
   map((org) => org?.reportingStandards ?? []),
   distinctUntilChanged(isEqual)
);

export const consolidationMethod$ = context$.pipe(
   map((org) => org?.consolidationMethod ?? 'FULL'),
   distinctUntilChanged(isEqual)
);

export const currencyCode$ = context$.pipe(
   map((org) => org?.currency?.shortCode),
   distinctUntilChanged()
);

export const currency$ = context$.pipe(
   map((org) => org?.currency),
   distinctUntilChanged()
);

export const type$ = context$.pipe(
   map((org) => org?.type ?? 'ORGANIZATION'),
   distinctUntilChanged()
);

export const emissionUnit$ = context$.pipe(
   map((org) => org?.emissionUnit ?? 'kg'),
   distinctUntilChanged()
);

export const subsidiaries$ = context$.pipe(
   map((org) => org?.subsidiaries ?? []),
   distinctUntilChanged(isEqual)
);

export const characteristics$ = context$.pipe(
   map((org) => org?.characteristics ?? []),
   distinctUntilChanged(isEqual)
);

export const results$ = context$.pipe(
   map((org) => org?.results ?? []),
   distinctUntilChanged(isEqual)
);

function flattenSubsidiaries(item) {
   return [item, flatMapDeep(item.subsidiaries, flattenSubsidiaries)];
}

export const subsidiaryIds$ = context$.pipe(
   filterNilValue(),
   map((activeOrganisation) => flatMapDeep([activeOrganisation], flattenSubsidiaries).map((org) => org.id)),
   distinctUntilChanged(isEqual)
);

export const parentIds$ = combineQueries([activeOrganisationId$, organisations$]).pipe(
   map(([activeOrganisationId, organisations]) =>
      Number.isInteger(activeOrganisationId) && Array.isArray(organisations) && organisations.length > 0
         ? getParentIds(activeOrganisationId, organisations)
         : []
   ),
   distinctUntilChanged(isEqual)
);

export const hasParents$ = organisationContextQuery
   .select((state) => state?.activeOrganisation?.parents ?? [])
   .pipe(
      map((parents) => parents?.length > 0),
      distinctUntilChanged()
   );

export const reducedContext$ = context$.pipe(
   map((org) => ({
      ...pick(org, [
         'id',
         'slug',
         'name',
         'code',
         'title',
         'favicon',
         'banner',
         'logo',
         'isWhiteLabel',
         'background',
         'theme',
         'allowMaterialityAssessment',
      ]),
      label: org?.code ? `(${org.code}) ${org?.name}` : org?.name,
   })),
   distinctUntilChanged(isEqual)
);

export const customization$ = context$.pipe(
   map((org) => org?.customization),
   distinctUntilChanged(isEqual)
);

export const theme$ = context$.pipe(
   map((org) => org?.theme),
   distinctUntilChanged(isEqual)
);
