import { DefaultReportEditor } from 'components/ReportEditor/DefaultReportEditor';
import { formatOptions } from 'components/shared';
import useOrganisationContext from 'context/OrganisationContext/hook';
import { groupBy, merge, padStart, pick, sortBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import useKPIFieldsFacade from 'state/KPIField/hook';
import useKPISubsFacade from 'state/KPISub/hook';
import useKPIValuesFacade from 'state/KPIValue/hook';
import useReportDocumentsFacade from 'state/ReportDocument/hook';
import useReportDocumentTemplatesFacade from 'state/ReportDocumentTemplates/hook';
import { mergeFormatOptions } from 'utils';
import { KPIValueType } from 'utils/enum';
import { formatNumber } from 'utils/helper';
import useUserContext from '../../context/UserContext/hook';

/**
 * A custom React hook that manages the configuration of a CKEditor instance.
 * It takes into account the default configuration, the user's language setting, and any additional configuration from props.config.
 * It dynamically updates the editor's configuration based on the user's language, the current period context, and the active organisation.
 *
 * @param {Object} props - Contains the initial configuration properties for the editor, including any overrides.
 * @param {Function} onMention - Callback function to handle the mention feature logic. This should be the {@link ReportEditorService#onMention} function.
 * @param {Object} htmlRefs - An object with properties where the values are React.MutableRefObject instances. These references are used to manage UI components related to the editor. Expected properties:
 *                            - `sidebarElementRef`: Refers to the sidebar container element.
 *                            - `presenceListElementRef`: Refers to the presence list element.
 *                            - `editorContainerRef`: Refers to the main editor container element.
 *                            - `viewerContainerElementRef`: Refers to the container element of the viewer mode.
 *                            - `viewerEditorElementRef`: Refers to the editor element in viewer mode.
 *                            - `viewerSidebarContainerElementRef`: Refers to the sidebar container element in viewer mode.
 * @returns {Object} An object containing the updated configuration for the CKEditor instance (`config`) and a dynamically generated unique identifier for the editor instance (`id`).
 */
export const useSetConfig = (props, onMention, htmlRefs) => {
   const intl = useIntl();
   const {
      state: { currencyCode },
   } = useOrganisationContext();

   const defaultValuesByType = useMemo(
      () => ({
         TEXT: 'Placeholder',
         SELECT: 'Selected Option',
         TEXTAREA:
            'Your work on this project has been really impactful you gotta smoke test your hypothesis, yet you better eat a reality sandwich before you walk back in that boardroom.',
         NUMBER: intl.formatNumber(1234.56).toString(),
         PERCENTAGE: intl.formatNumber(Math.random(), { style: 'percent' }),
         FORMULA: intl.formatNumber(1234.56),
         DATE: intl.formatDate(new Date(), { dateStyle: 'medium' }),
         SURFACE: `${intl.formatNumber(1234.56)} m²`,
         ELECTRICITY: `${intl.formatNumber(1234.56)} kWh`,
         WEIGHT: `${intl.formatNumber(1234.56)} kg`,
         MEASUREMENT: `${intl.formatNumber(1234.56)} m`,
         VOLUME: `${intl.formatNumber(1234.56)} l`,
         EMISSIONS: `${intl.formatNumber(1234.56)} kg CO²`,
         EMISSION_SELECT: `${intl.formatNumber(1234.56)} kg CO²`,
         SWITCH: `Yes`,
         PRICE: intl.formatNumber(1234.56, { style: 'currency', currency: currencyCode ?? 'EUR', currencyDisplay: 'code' }),
         TABLE: 'Table Placeholder',
      }),
      [currencyCode, intl]
   );

   const {
      state: { supportedLanguage: userLanguage, reportEditorMergeFieldsPreviewMode, locale },
   } = useUserContext();
   const {
      state: { activeDocumentId, activeEntity: activeReportDocument },
   } = useReportDocumentsFacade();

   const {
      state: { entities: kpiFields, entitiesTableColumns: tableColumns },
   } = useKPIFieldsFacade();

   const {
      state: { allEntities: kpiSubs },
   } = useKPISubsFacade();

   const {
      api: kpiValuesAPI,
      state: { reportData },
   } = useKPIValuesFacade();

   const {
      api: reportDocumentTemplatesAPI,
      state: { entities: reportDocumentTemplates },
   } = useReportDocumentTemplatesFacade();

   useEffect(() => {
      kpiValuesAPI.getReportData();

      // return kpiValuesAPI.unsubscribeReportData();
   }, [kpiValuesAPI]);

   useEffect(() => {
      reportDocumentTemplatesAPI.getEntities();
   }, [reportDocumentTemplatesAPI]);

   const relevantReportingStandardIds = useMemo(
      () => (activeReportDocument ? activeReportDocument.report.standards.map(({ id }) => id) : []),
      [activeReportDocument]
   );

   const documentFormat = useMemo(() => new Intl.NumberFormat(locale ?? 'en-US'), [locale]);

   const defaultFormatOptions = useMemo(() => pick(documentFormat.resolvedOptions(), formatOptions), [documentFormat]);

   const envoriaTables = useMemo(
      () => ({
         tables: sortBy(
            kpiFields.filter(({ type }) => type === 'TABLE'),
            (kpiField) => {
               const kpiSub = kpiSubs.find(({ id }) => id === kpiField.kpiContent.kpiSub.id);

               const groupString = Number.parseInt(
                  `${padStart(kpiSub.kpi.kpiArea.position, 4, 0)}${padStart(kpiSub.kpi.position, 4, 0)}${padStart(kpiSub.position, 4, 0)}`
               );

               return groupString;
            }
         ).map(({ identifier, name, columns, rows, columnName }) => ({
            id: identifier,
            label: name,
            columnName,
            rows: rows.map(({ name, slug }) => ({ identifier: `${identifier}_${slug}`, name })),
            columns: columns.map(({ id }) => {
               const column = tableColumns.find(({ id: columnId }) => columnId === id);

               return { identifier: column?.identifier, name: column?.name };
            }),
         })),
      }),
      [kpiFields, kpiSubs, tableColumns]
   );

   const template = useMemo(
      () => ({
         definitions: reportDocumentTemplates.map(({ name: title, desc: description, data }) => ({
            title,
            description,
            data,
         })),
      }),
      [reportDocumentTemplates]
   );

   const mergeFields = useMemo(
      () => ({
         initialPreviewMode: reportEditorMergeFieldsPreviewMode ?? '$labels',
         previewHtmlValues: true,
         previewModes: ['$labels', '$dataSets'],
         dataSets: [
            {
               id: 'kpiValues',
               label: intl.formatMessage({ id: 'reportEditor.mergeFields.values', defaultMessage: 'Values' }),
               values: reportData.reduce((valueObj, kpiValue) => {
                  const relevantField = kpiFields.find(({ id }) => id === kpiValue.kpiFieldId);

                  let formattedValue;

                  let kpiObj = kpiValue;

                  if (kpiValue.attributes?.length > 0) {
                     [kpiObj] = kpiValue.attributes;
                  }

                  let style = 'decimal';

                  if (kpiObj.unit === '%') {
                     style = 'percent';
                  } else if (kpiObj.unit) {
                     style = 'unit';
                  }

                  switch (kpiObj.dataType) {
                     case KPIValueType.NUMBER:
                     case KPIValueType.DECIMAL:
                        formattedValue = formatNumber(
                           intl,
                           kpiObj.value,
                           kpiObj.unit,
                           mergeFormatOptions({ ...relevantField?.format, style }, defaultFormatOptions)
                        );
                        break;
                     case KPIValueType.DATE:
                        formattedValue = intl.formatDate(kpiObj.value, { dateStyle: 'medium' });
                        break;
                     case KPIValueType.BOOLEAN:
                        formattedValue = intl.formatMessage({
                           id: `reportEditor.mergeFields.${kpiObj.value.toString()}`,
                           defaultMessage: kpiObj.toString(),
                        });
                        break;
                     case KPIValueType.TEXT:
                     default:
                        formattedValue = kpiObj.value;
                        break;
                  }

                  return {
                     ...valueObj,
                     [(kpiValue.rowIdentifier ?? kpiValue.identifier)
                        .replace(/\s/g, '-')
                        .replace('‑', '-')
                        .concat(kpiValue.attributes?.length > 0 ? '_'.concat(kpiValue.attributes[0].identifier) : '')]: formattedValue,
                  };
               }, {}),
            },
         ],
         definitions: sortBy(
            Object.entries(
               groupBy(
                  kpiFields.filter(
                     ({ reportingStandard, kpiContent, scope, fieldGroupId }) =>
                        !fieldGroupId && scope !== false && relevantReportingStandardIds.includes(reportingStandard.id) && kpiContent?.kpiSub
                  ),
                  (o) => o.kpiContent.kpiSub.id
               )
            ),
            ([groupId]) => {
               const kpiSub = kpiSubs.find(({ id }) => id === Number.parseInt(groupId, 10));

               const groupString = Number.parseInt(
                  `${padStart(kpiSub.kpi.kpiArea.position, 4, 0)}${padStart(kpiSub.kpi.position, 4, 0)}${padStart(kpiSub.position, 4, 0)}`
               );

               return groupString;
            }
         ).map(([groupId, theFields]) => {
            const kpiSub = kpiSubs.find(({ id }) => id === Number.parseInt(groupId, 10));

            return {
               groupId,
               groupLabel: kpiSub?.key ? `[${kpiSub.key.replace('‑', '-')}] ${kpiSub.name}` : kpiSub?.name,
               definitions: sortBy(theFields, (o) => `${o.kpiContent.position}${o.position}`).flatMap(
                  ({ identifier, name: label, type, rows, columns }) => {
                     if (type === 'TABLE') {
                        return columns.flatMap(({ type, name: colName, identifier: colIdentifier }) =>
                           rows.map(({ name: rowName, slug: rowSlug }) => ({
                              id: identifier.replace(/\s/g, '-').replace('‑', '-').concat('_').concat(rowSlug).concat('_').concat(colIdentifier),
                              label: `${label} - ${colName} - ${rowName}`,
                              defaultValue: defaultValuesByType[type],
                              type: 'text',
                           }))
                        );
                     } else {
                        return {
                           id: identifier.replace(/\s/g, '-').replace('‑', '-'),
                           label,
                           defaultValue: defaultValuesByType[type],
                           type: 'text',
                        };
                     }
                  }
               ),
            };
         }),
      }),
      [
         defaultFormatOptions,
         defaultValuesByType,
         intl,
         kpiFields,
         kpiSubs,
         relevantReportingStandardIds,
         reportData,
         reportEditorMergeFieldsPreviewMode,
      ]
   );

   const {
      editorContainerRef,
      editorPresenceRef,
      editorOutlineRef,
      editorAnnotationsRef,
      editorRevisionHistoryRef,
      editorRevisionHistoryEditorRef,
      editorRevisionHistorySidebarRef,
   } = htmlRefs;

   const [editorsConfig, setEditorsConfig] = useState(merge({}, DefaultReportEditor.defaultConfig, props.config, { language: userLanguage }));

   useEffect(() => {
      setEditorsConfig((currentConfig) => ({
         ...merge(currentConfig, props.config, {
            envoriaTables,
            language: userLanguage,
            comments: {
               editorConfig: {
                  mention: {
                     dropDownLimit: 5,
                     feeds: [
                        {
                           marker: '@',
                           feed: (queryText) => onMention(queryText),
                           minimumCharacters: 3,
                        },
                     ],
                  },
               },
            },
            presenceList: {
               container: editorPresenceRef.current,
            },
            revisionHistory: {
               editorContainer: editorContainerRef.current,
               viewerContainer: editorRevisionHistoryRef.current,
               viewerEditorElement: editorRevisionHistoryEditorRef.current,
               viewerSidebarContainer: editorRevisionHistorySidebarRef.current,
               resumeUnsavedRevision: true,
            },
            sidebar: {
               container: editorAnnotationsRef.current,
            },
            documentOutline: {
               container: editorOutlineRef.current,
            },
            template,
            collaboration: {
               channelId: activeDocumentId,
            },
            exportWord: {
               fileName: `${activeReportDocument?.name}.docx`,
            },
         }),
         mergeFields,
      }));
   }, [
      userLanguage,
      props.config,
      onMention,
      editorContainerRef,
      props.id,
      kpiFields,
      mergeFields,
      activeDocumentId,
      editorPresenceRef,
      editorRevisionHistoryRef,
      editorRevisionHistoryEditorRef,
      editorRevisionHistorySidebarRef,
      editorAnnotationsRef,
      editorOutlineRef,
      template,
      activeReportDocument?.name,
      envoriaTables,
   ]);

   return { config: editorsConfig };
};
