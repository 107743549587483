import { DiagrammType } from "utils/enum";

export const layoutPropsToSave = ['i', 'x', 'y', 'w', 'h', 'minW', 'minH', 'maxW', 'maxH'];

export const layoutConfig = {
   rowHeight: 200,
   columns: 4,
   sizes: [
      { w: 1, h: 1, minW: 1, minH: 1, maxW: 2, maxH: 1 },
      { w: 2, h: 2, minW: 2, minH: 2, maxW: 4, maxH: 2 },
   ],
};

export const sizeLimitations = {
   [layoutConfig.sizes[0]]: [DiagrammType.NUMBER, DiagrammType.PERCENT],
};

export const adjustLayouts = (layouts = [], charts = []) => {
      return layouts.length
         ? layouts.reduce((acc, _layout) => {
            const layout = { ..._layout };
            const chart = charts.find((chart) => chart?.id === Number.parseInt(layout.i, 10));
            if (!chart) {
               return acc;
            }
            // limitations for bigger charts
            if (!sizeLimitations[layoutConfig.sizes[0]].includes(chart?.type)) {
               const scale = 2;
               if ((layout?.w === 1 && layout?.h === 1) || (layout?.w === 2 && layout?.h === 1)) {
                  layout.w *= scale;
                  layout.minW = layoutConfig.sizes[1].minW;
                  layout.maxW = layoutConfig.sizes[1].maxW;
                  layout.h *= scale;
                  layout.minH = layoutConfig.sizes[1].minH;
                  layout.maxH = layoutConfig.sizes[1].maxH;
                  layout.x *= scale;
                  layout.y *= scale;
               }
               if (!layout?.maxW) {
                  layout.maxW = layoutConfig.sizes[1].maxW;
               }
               if (!layout?.maxH) {
                  layout.maxH = layoutConfig.sizes[1].maxH;
               }
            }
            // limitations for small charts
            else {
               if (!layout?.maxW) {
                  layout.maxW = layoutConfig.sizes[0].maxW;
               }
               if (!layout?.maxH) {
                  layout.maxH = layoutConfig.sizes[0].maxH;
               }
            }
            //   if (chart?.type === DiagrammType.TABLE) {
            //       layout.w = chart?.aggregationLevel === AggregationLevel.MONTH ? 3 : layoutConfig.sizes[1].w;
            //       layout.minW = chart?.aggregationLevel === AggregationLevel.MONTH ? 3 : layoutConfig.sizes[1].minW;
            //   }
            return [...acc, layout];
           }, [])
         : // there ace cases where we don't have predefined layouts
           // in this case we need to create them based on chart-data
           charts.map((chart, index) => ({
              i: chart?.id?.toString() ?? index.toString(),
              ...(sizeLimitations[layoutConfig.sizes[0]].includes(chart?.type) ? layoutConfig.sizes[0] : layoutConfig.sizes[1]),
              x: (index * 2) % layoutConfig.columns,
              y: 0,
           }));
}