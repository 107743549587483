import { subject } from '@casl/ability';
import {
   ArchiveOutlined,
   CancelScheduleSendOutlined,
   Password,
   SendOutlined,
   ThumbDownOutlined,
   ThumbUpOutlined,
   UnarchiveOutlined,
   UndoOutlined,
   VisibilityOutlined,
} from '@mui/icons-material';
import { Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ability from 'casl/ability';
import Icon from 'components/Icon';
import { intervalToDuration } from 'date-fns';
import { country } from 'iso-3166-2';
import { get, has, isEmpty, isObject, omit } from 'lodash-es';
import { evaluate } from 'mathjs';
import React from 'react';
import sanctionedUnits from 'resources/json/sanctionedUnits.json';
import { ApprovalStatus, CommonActionType, KPIFieldType, KPIValueType } from 'utils/enum';

export const interpolate = /\{\{([\s\S]{1,256}?)\}\}/g;

export function descendingComparator(a, b, orderBy) {
   const left = get(a, orderBy);
   const right = get(b, orderBy);
   if (right < left) {
      return -1;
   }
   if (right > left) {
      return 1;
   }
   return 0;
}

export function getComparator(order, orderBy) {
   return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
   const stabilizedThis = array.map((el, index) => [el, index]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
         return order;
      }
      return a[1] - b[1];
   });
   return stabilizedThis.map((el) => el[0]);
}

export const getKPIValueType = (kpiFieldType) => {
   switch (kpiFieldType) {
      case KPIFieldType.SELECT:
         return KPIValueType.INTEGER;
      case KPIFieldType.SWITCH:
         return KPIValueType.BOOLEAN;
      case KPIFieldType.TABLE:
      case KPIFieldType.WEIGHT:
      case KPIFieldType.SURFACE:
      case KPIFieldType.PRICE:
      case KPIFieldType.MEASUREMENT:
      case KPIFieldType.EMISSIONS:
      case KPIFieldType.EMISSION_SELECT:
      case KPIFieldType.DISTANCE:
      case KPIFieldType.ELECTRICITY:
      case KPIFieldType.VOLUME:
      case KPIFieldType.PERCENTAGE:
      case KPIFieldType.NUMBER:
      case KPIFieldType.WOOD:
         return KPIValueType.DECIMAL;
      case KPIFieldType.TEXT:
      case KPIFieldType.TEXTAREA:
         return KPIValueType.TEXT;
      case KPIFieldType.DATE:
         return KPIValueType.INTEGER;
      default:
         return KPIValueType.TEXT;
   }
};

export const getDirtyFormValues = (dirtyFields, formValues) => {
   if (dirtyFields === true) {
      return formValues;
   }

   if (Array.isArray(dirtyFields)) {
      return dirtyFields
         .map((dirtyField, index) => getDirtyFormValues(dirtyField, formValues?.[index]))
         .filter((value) => {
            if (value && Object.getPrototypeOf(value) === Object.prototype) {
               return !isEmpty(value);
            }
            if (Array.isArray(value)) {
               return value.filter((val) => !isEmpty(val));
            }
            return true;
         });
   }

   return Object.fromEntries(
      Object.entries(dirtyFields)
         .filter(([key, isDirty]) => formValues && has(formValues, key) && isDirty !== false && !(Array.isArray(isDirty) && isDirty.length === 0))
         .map(([key, value]) => [key, getDirtyFormValues(value, formValues[key])])
         .filter(([, value]) => {
            if (value && Object.getPrototypeOf(value) === Object.prototype) {
               return !isEmpty(value);
            }
            if (Array.isArray(value)) {
               return value.filter((val) => !isEmpty(val));
            }
            return true;
         })
   );
};

export const checkObjectKeys = (object, value) => Object.keys(object).some((key) => key.includes(value));

export const getReviewApproveAllStatus = (objectList, key) => {
   let canReviewAll = false;
   let canApproveAll = false;
   let canUnapproveAll = false;
   let canUnreviewAll = false;
   let actions = [];

   if (objectList && objectList.length > 0 && objectList.every((obj) => Object.hasOwn(obj, key))) {
      const allInProg = objectList.every((obj) => obj[key] === ApprovalStatus.IN_PROGRESS);
      const allReviewed = objectList.every((obj) => obj[key] === ApprovalStatus.REVIEWED);
      const allApproved = objectList.every((obj) => obj[key] === ApprovalStatus.APPROVED);
      const someNotAllInProg = objectList.some((obj) => obj[key] === ApprovalStatus.IN_PROGRESS) && !allInProg;
      const someNotAllReviewed = objectList.some((obj) => obj[key] === ApprovalStatus.REVIEWED) && !allReviewed;
      const someNotAllApproved = objectList.some((obj) => obj[key] === ApprovalStatus.APPROVED) && !allApproved;
      const noneApproved = !allApproved && !someNotAllApproved;
      const noneArchived = !objectList.some((obj) => obj[key] === ApprovalStatus.ARCHIVED);
      const noneInProg = !allInProg && !someNotAllInProg;

      canReviewAll = Boolean(allInProg || (someNotAllReviewed && noneApproved));
      canApproveAll = Boolean(allReviewed || (someNotAllApproved && noneInProg && noneArchived));
      canUnapproveAll = Boolean(allApproved || (someNotAllApproved && noneArchived));
      canUnreviewAll = Boolean(allReviewed || (someNotAllReviewed && noneApproved));

      const canDo = {
         [CommonActionType.APPROVE]:
            canApproveAll &&
            objectList.every((rate) => ability.can('approve', subject('currencyRate', { ...rate })) || rate.status === ApprovalStatus.APPROVED),
         [CommonActionType.UNAPPROVE]:
            allApproved &&
            objectList.every((rate) =>
               ability.can(
                  'approve',
                  subject('currencyRate', {
                     ...rate,
                     status: ApprovalStatus.REVIEWED,
                     approverId: null,
                     approver: null,
                     lastModifier: null,
                     lastModifierId: null,
                  })
               )
            ),
      };
      actions = Object.keys(canDo).filter((action) => canDo[action]);
   }

   return {
      canReviewAll,
      canApproveAll,
      canUnapproveAll,
      canUnreviewAll,
      actions,
   };
};

export const commonActionDetails = new Map([
   [CommonActionType.APPROVE, { icon: <ThumbUpOutlined />, label: 'Approve all' }],
   [CommonActionType.REVIEW, { icon: <VisibilityOutlined />, label: 'Review all' }],
   [CommonActionType.UNAPPROVE, { icon: <ThumbDownOutlined />, label: 'Withdraw all approvals' }],
   [CommonActionType.UNREVIEW, { icon: <CancelScheduleSendOutlined />, label: 'Cancel all approval requests' }],
]);

export function formatNumber(intl, value, unit, formatOptions) {
   const prefix = `${formatOptions?.prefix ?? ''}`;
   const _value = `${intl.formatNumber(formatOptions?.style === 'percent' ? value / 100 : value, {
      ...omit(formatOptions, ['prefix', 'suffix', 'style', 'unitDisplay', 'minimumSignificantDigits', 'maximumSignificantDigits']),
      style: !sanctionedUnits.includes(unit) && formatOptions?.style === 'unit' ? 'decimal' : formatOptions?.style,
      ...(sanctionedUnits.includes(unit) && formatOptions?.unitDisplay ? { unitDisplay: formatOptions?.unitDisplay } : {}),
   })}`;
   const separator = `${formatOptions?.style === 'unit' && unit && !sanctionedUnits.includes(unit) && formatOptions?.unitDisplay === 'short' ? ' ' : ''}`;
   const _unit = `${formatOptions?.style === 'unit' && unit && !sanctionedUnits.includes(unit) ? unit : ''}`;
   const suffix = `${formatOptions?.suffix ?? ''}`;

   return `${prefix}${_value}${separator}${_unit}${suffix}`;
}

export function getProviderIcon(provider) {
   switch (provider) {
      case 'GOOGLE':
         return <Icon icon="google" fontSize={40} />;
      case 'AZURE':
         return <Icon icon="microsoft" />;
      case 'SLACK':
         return <Icon icon="slack" />;
      case 'GITHUB':
         return <Icon icon="github" fontSize={20} />;
      case 'OKTA':
         return <Icon icon="okta" />;
      case 'WEBAUTHN':
         return <Icon icon="webauthn" />;
      default:
         return <Password />;
   }
}

export function formatErrors(errorList, intl) {
   const sanitizedErrorList = errorList.filter((err) => isObject(err));

   if (sanitizedErrorList.length === 0) {
      return null;
   }

   return (
      <>
         <Typography variant="inherit">
            {intl.formatMessage({ id: 'errors.conversionTitle', defaultMessage: 'Errors occured while calculating:' })}
         </Typography>
         {sanitizedErrorList.map((errObj, index) => (
            <Typography variant="inherit" key={index}>{`- ${intl.formatMessage(
               { id: `errors.${errObj?.error ?? 'unknown_error'}`, defaultMessage: errObj?.error ?? '' },
               {
                  ...errObj,
                  ...(errObj?.kind
                     ? {
                          kind: intl.formatMessage({
                             id: `entityKind.${errObj?.kind}`,
                             defaultMessage: errObj?.kind,
                          }),
                       }
                     : {}),
               }
            )}`}</Typography>
         ))}
      </>
   );
}

export const VirtuosoTableComponents = {
   Scroller: React.forwardRef((props, ref) => <TableContainer {...props} ref={ref} />),
   Table: (props) => <Table {...props} style={{ borderCollapse: 'separate', tableLayout: 'auto' }} size="small" />,
   TableHead,
   TableRow,
   TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
   Footer: () => (
      <div
         style={{
            padding: '2rem',
            display: 'flex',
            justifyContent: 'center',
         }}
      >
         Loading...
      </div>
   ),
};

export const emailPattern = /^([\w+&*-]{1,64}(?:\.[\w+&*-]{1,32}){0,64})@(?:[a-z\d-]{0,253}\.){1,251}[a-z]{2,63}$/i;

export function renderESRSScopeTooltip(intl, reason) {
   return (
      <>
         <Typography variant="caption">
            {intl.formatMessage(
               {
                  id: 'kpiscopes.esrs.outOfScope.tooltip',
                  defaultMessage:
                     'This item has been classified as not material for the selected reporting period. You can change the materiality assessment in the sidebar in edit mode. {br}{br} Reason: ',
               },
               {
                  br: <br />,
               }
            )}
         </Typography>
         {!reason || reason === '' || typeof reason !== 'string'
            ? intl.formatMessage({
                 id: 'kpiscopes.outOfScope.noReason',
                 defaultMessage: 'No explanation provided',
              })
            : reason.split('\n').map((reason, index) => (
                 <React.Fragment key={index}>
                    <Typography variant="caption" key={index}>
                       {reason}
                    </Typography>
                    <br />
                 </React.Fragment>
              ))}
      </>
   );
}

export function renderOutOfScopeReason(intl, reason) {
   return intl.formatMessage(
      {
         id: 'kpiscopes.outOfScope.tooltip',
         defaultMessage:
            'This item has been classified as not material for the selected reporting period. {br} Reason: {reason} {br} You can change the materiality assessment in the sidebar in edit mode.',
      },
      {
         reason:
            !reason || reason === '' || typeof reason !== 'string'
               ? intl.formatMessage({
                    id: 'kpiscopes.outOfScope.noReason',
                    defaultMessage: 'No explanation provided',
                 })
               : reason,
         br: <br />,
      }
   );
}

export function renderEmissionScopeText(intl, reason) {
   return intl.formatMessage(
      {
         id: 'emissions.outOfScope.tooltip',
         defaultMessage:
            'This item has been classified as not material for the selected reporting period. You can change the materiality in the footprint in edit mode. {br}{br} Reason: {reason}',
      },
      {
         br: <br />,
         reason:
            !reason || reason === '' || typeof reason !== 'string'
               ? intl.formatMessage({
                    id: 'emissions.outOfScope.noReason',
                    defaultMessage: 'No explanation provided',
                 })
               : reason,
      }
   );
}

export function renderEmissionScopeReasonText(intl, reason) {
   return intl.formatMessage(
      {
         id: 'emissions.inScope.tooltip',
         defaultMessage: 'This item has been classified as material for the selected reporting period. {br}{br} Reason: {reason}',
      },
      {
         br: <br />,
         reason:
            !reason || reason === '' || typeof reason !== 'string'
               ? intl.formatMessage({
                    id: 'emissions.outOfScope.noReason',
                    defaultMessage: 'No explanation provided',
                 })
               : reason,
      }
   );
}

export function getRelativeTimeInterval(date) {
   const interval = intervalToDuration({
      start: date,
      end: new Date(),
   });

   let value;
   let unit;

   if (interval.years >= 1) {
      unit = 'year';
      value = interval.years;
   } else if (interval.years < 1 && interval.months > 0) {
      value = interval.months;
      unit = 'month';
   } /* else if (interval.months < 1 && (interval?.weeks ?? 0) > 0) {
      value = interval?.weeks ?? 0;
      unit = 'week';
   } */ else if (interval?.months < 1 && interval.days > 0) {
      value = interval.days;
      unit = 'day';
   } else if (interval.days < 1 && interval.hours > 0) {
      value = interval.hours;
      unit = 'hour';
   } else if (interval.hours < 1 && interval.minutes > 0) {
      value = interval.minutes;
      unit = 'minute';
   } else if (interval.minutes < 1) {
      value = interval.seconds;
      unit = 'second';
   }

   return {
      value,
      unit,
   };
}

export function getRelativeTime(intl, date, options = { numeric: 'auto' }) {
   const interval = getRelativeTimeInterval(date);

   return intl.formatRelativeTime(-interval.value, interval.unit, { style: 'narrow', ...options });
}

export function mergeRefs(...refs) {
   return (node) => {
      for (const ref of refs) {
         if (typeof ref === 'function') {
            ref(node);
         } else if (ref != null) {
            ref.current = node;
         }
      }
   };
}

export function auditActionConfirm(intl, modelName) {
   return {
      review: {
         icon: <SendOutlined fontSize="small" />,
         confirmationDialog: {
            title: intl.formatMessage(
               {
                  id: 'auditActionDialog.review.confirmationTitle',
                  defaultMessage:
                     'Do you really want to mark this {requestedModel} as ready for approval for this period? Other periods are not affected.',
               },
               {
                  requestedModel: intl.formatMessage({
                     id: `auditActionDialog.modelNames.${modelName}`,
                     defaultMessage: `${(modelName ?? '').toLowerCase()}`,
                  }),
               }
            ),
         },
         buttonTitle: intl.formatMessage({
            id: 'auditActionDialog.review.buttonTitle',
            defaultMessage: 'Request approval',
         }),
      },
      unreview: {
         icon: <CancelScheduleSendOutlined fontSize="small" />,
         confirmationDialog: {
            title: intl.formatMessage(
               {
                  id: 'auditActionDialog.unreview.confirmationTitle',
                  defaultMessage:
                     'Do you really want to remove the review for this {requestedModel} for this period? Other periods are not affected.',
               },
               {
                  requestedModel: intl.formatMessage({
                     id: `auditActionDialog.modelNames.${modelName}`,
                     defaultMessage: `${(modelName ?? '').toLowerCase()}`,
                  }),
               }
            ),
         },
         buttonTitle: intl.formatMessage({
            id: 'auditActionDialog.unreview.buttonTitle',
            defaultMessage: 'Withdraw approval request',
         }),
      },
      approve: {
         icon: <ThumbUpOutlined fontSize="small" />,
         confirmationDialog: {
            title: intl.formatMessage(
               {
                  id: 'auditActionDialog.approve.confirmationTitle',
                  defaultMessage: 'Do you really want to approve {requestedModel} for this period? Other periods are not affected.',
               },
               {
                  requestedModel: intl.formatMessage({
                     id: `auditActionDialog.modelNames.${modelName}`,
                     defaultMessage: `${(modelName ?? '').toLowerCase()}`,
                  }),
               }
            ),
         },
         buttonTitle: intl.formatMessage({
            id: 'auditActionDialog.approve.buttonTitle',
            defaultMessage: 'Approve',
         }),
      },
      reject: {
         icon: <ThumbDownOutlined fontSize="small" />,
         confirmationDialog: {
            title: intl.formatMessage(
               {
                  id: 'auditActionDialog.reject.confirmationTitle',
                  defaultMessage: 'Do you really want to reject this {requestedModel} for this period? Other periods are not affected.',
               },
               {
                  requestedModel: intl.formatMessage({
                     id: `auditActionDialog.modelNames.${modelName}`,
                     defaultMessage: `${(modelName ?? '').toLowerCase()}`,
                  }),
               }
            ),
         },
         buttonTitle: intl.formatMessage({
            id: 'auditActionDialog.reject.buttonTitle',
            defaultMessage: 'Reject',
         }),
      },
      unapprove: {
         icon: <UndoOutlined fontSize="small" />,
         confirmationDialog: {
            title: intl.formatMessage(
               {
                  id: 'auditActionDialog.unapprove.confirmationTitle',
                  defaultMessage: 'Do you really want to remove the approval for {requestedModel} for this period? Other periods are not affected.',
               },
               {
                  requestedModel: intl.formatMessage({
                     id: `auditActionDialog.modelNames.${modelName}`,
                     defaultMessage: `${(modelName ?? '').toLowerCase()}`,
                  }),
               }
            ),
         },
         buttonTitle: intl.formatMessage({
            id: 'auditActionDialog.unapprove.buttonTitle',
            defaultMessage: 'Withdraw approval',
         }),
      },
      archive: {
         icon: <ArchiveOutlined fontSize="small" />,
         confirmationDialog: {
            title: intl.formatMessage(
               {
                  id: 'auditActionDialog.archive.confirmationTitle',
                  defaultMessage: 'Do you really want to archive {requestedModel} for this period? Other periods are not affected.',
               },
               {
                  requestedModel: intl.formatMessage({
                     id: `auditActionDialog.modelNames.${modelName}`,
                     defaultMessage: `${(modelName ?? '').toLowerCase()}`,
                  }),
               }
            ),
         },
         buttonTitle: intl.formatMessage({
            id: 'auditActionDialog.archive.buttonTitle',
            defaultMessage: 'Archive',
         }),
      },
      unarchive: {
         icon: <UnarchiveOutlined fontSize="small" />,
         confirmationDialog: {
            title: intl.formatMessage(
               {
                  id: 'auditActionDialog.unarchive.confirmationTitle',
                  defaultMessage: 'Do you really want to unarchive {requestedModel} for this period? Other periods are not affected.',
               },
               {
                  requestedModel: intl.formatMessage({
                     id: `auditActionDialog.modelNames.${modelName}`,
                     defaultMessage: `${(modelName ?? '').toLowerCase()}`,
                  }),
               }
            ),
         },
         buttonTitle: intl.formatMessage({
            id: 'auditActionDialog.unarchive.buttonTitle',
            defaultMessage: 'Unarchive',
         }),
      },
   };
}

const approvalCenterSubjectList = {
   businessactivity: 'businessActivity',
   kpifield: 'kpiField',
   footprint: 'footprint',
   footprintsub: 'footprintSub',
   fpcalculation: 'fpCalculation',
   fpcalculationfield: 'fpCalculationField',
   kpiscope: 'kpiScope',
   kpisub: 'kpiSub',
   kpitopic: 'kpiTopic',
   kpivalue: 'kpiValue',
   report: 'report',
   taxonomycriteriaresponse: 'taxonomyCriteriaResponse',
   materialityscalevalue: 'materialityScaleValue',
};

export function abilityShowAuditButtons(abilitySubject, checks) {
   let reviewItemCheck =
      ability.can(
         'review',
         subject(abilitySubject.__caslSubjectType__, {
            ...abilitySubject,
            status: ApprovalStatus.IN_PROGRESS,
         })
      ) && abilitySubject?.status === ApprovalStatus.IN_PROGRESS;
   let unreviewItemCheck =
      !ability.can(
         'approve',
         subject(abilitySubject.__caslSubjectType__, {
            ...abilitySubject,
            status: ApprovalStatus.REVIEWED,
         })
      ) &&
      ability.can(
         'review',
         subject(abilitySubject.__caslSubjectType__, {
            ...abilitySubject,
            status: ApprovalStatus.IN_PROGRESS,
         })
      ) &&
      abilitySubject?.status === ApprovalStatus.REVIEWED;
   let rejectItemCheck = ability.can('approve', abilitySubject) && abilitySubject?.status === ApprovalStatus.REVIEWED;
   let approveItemCheck = ability.can('approve', abilitySubject) && abilitySubject?.status === ApprovalStatus.REVIEWED;
   let unapproveItemCheck =
      ability.can(
         'approve',
         subject(abilitySubject.__caslSubjectType__, {
            ...abilitySubject,
            status: ApprovalStatus.REVIEWED,
         })
      ) && abilitySubject?.status === ApprovalStatus.APPROVED;
   let archiveItemCheck = ability.can('archive', abilitySubject) && abilitySubject?.status === ApprovalStatus.APPROVED;
   const unarchiveItemCheck =
      ability.can(
         'archive',
         subject(abilitySubject.__caslSubjectType__, {
            ...abilitySubject,
            status: ApprovalStatus.APPROVED,
         })
      ) && abilitySubject?.status === ApprovalStatus.ARCHIVED;

   if (checks?.review) {
      if (abilitySubject.__caslSubjectType__ === 'approvalCenter') {
         reviewItemCheck = checks.review('review', approvalCenterSubjectList[abilitySubject?.kind], abilitySubject?.entities);
      } else {
         reviewItemCheck = checks.review();
      }
   }
   if (checks?.unreview) {
      if (abilitySubject.__caslSubjectType__ === 'approvalCenter') {
         unreviewItemCheck = checks.unreview('unreview', approvalCenterSubjectList[abilitySubject?.kind], abilitySubject?.entities);
      } else {
         unreviewItemCheck = checks.unreview();
      }
   }
   if (checks?.reject) {
      if (abilitySubject.__caslSubjectType__ === 'approvalCenter') {
         rejectItemCheck = checks.reject('reject', approvalCenterSubjectList[abilitySubject?.kind], abilitySubject?.entities);
      } else {
         rejectItemCheck = checks.reject();
      }
   }
   if (checks?.approve) {
      if (abilitySubject.__caslSubjectType__ === 'approvalCenter') {
         approveItemCheck = checks.approve('approve', approvalCenterSubjectList[abilitySubject?.kind], abilitySubject?.entities);
      } else {
         approveItemCheck = checks.approve();
      }
   }
   if (checks?.unapprove) {
      if (abilitySubject.__caslSubjectType__ === 'approvalCenter') {
         unapproveItemCheck = checks.unapprove('unapprove', approvalCenterSubjectList[abilitySubject?.kind], abilitySubject?.entities);
      } else {
         unapproveItemCheck = checks.unapprove();
      }
   }
   if (checks?.archive) {
      if (abilitySubject.__caslSubjectType__ === 'approvalCenter') {
         archiveItemCheck = checks.archive('archive', approvalCenterSubjectList[abilitySubject?.kind], abilitySubject?.entities);
      } else {
         archiveItemCheck = checks.archive();
      }
   }
   if (checks?.unarchive) {
      if (abilitySubject.__caslSubjectType__ === 'approvalCenter') {
         archiveItemCheck = checks.unarchive('unarchive', approvalCenterSubjectList[abilitySubject?.kind], abilitySubject?.entities);
      } else {
         archiveItemCheck = checks.unarchive();
      }
   }

   return {
      review: reviewItemCheck,
      unreview: unreviewItemCheck,
      reject: rejectItemCheck,
      approve: approveItemCheck,
      unapprove: unapproveItemCheck,
      archive: archiveItemCheck,
      unarchive: unarchiveItemCheck,
   };
}

export function formatCountryCode(code) {
   let formattedName;
   try {
      const name = country(code)?.name;
      formattedName = name ? `${name} (${code})` : code;
   } catch {
      formattedName = code;
   }
   return formattedName;
}

export const getRefArea = (fieldName) => (fieldName.endsWith('ies') ? `${fieldName.replace('ies', '')}` : `${fieldName.slice(0, -1)}`);

export function getReferencedObjects(entity) {
   return (
      (entity.referencedAreas ?? [])
         .map((propName) => (entity[propName] ?? []).map((refObject) => ({ ...refObject, kind: getRefArea(propName) })))
         .flat() ?? []
   );
}

export const disableDeletionTooltipTitle = (intl) => (
   <>
      <Typography variant="caption">
         {intl.formatMessage({
            id: 'kpis.kpisubs.kpicontent.kpifields.attachments.underAudit',
            defaultMessage: 'Attachment cannot be deleted because it has been reviewed or approved.',
         })}
      </Typography>
      <br />
      <br />
   </>
);

export const calculateValidationRules = (validationRules, kpiValuesSum) => {
   if (Array.isArray(validationRules) && (validationRules ?? []).length > 0 && kpiValuesSum) {
      const sumOfValidations = [];
      const rules = (validationRules ?? []).map((rule) => {
         let min = rule?.referenceValueSum;
         let max = rule?.referenceValueSum;
         let toleranceValue = rule?.toleranceValue ?? 0;
         if ('PERCENTAGE' === rule?.tolerance) {
            toleranceValue = (toleranceValue / 100) * rule?.referenceValueSum;
         }
         if ('NONE' !== rule?.tolerance) {
            min = rule?.referenceValueSum - toleranceValue;
            max = rule?.referenceValueSum + toleranceValue;
         }

         let calcResult;
         if (['>=', '>'].includes(rule?.comparator)) {
            calcResult = evaluate(`${kpiValuesSum} ${rule?.comparator} ${min}`);
         } else if (['<=', '<'].includes(rule?.comparator)) {
            calcResult = evaluate(`${kpiValuesSum} ${rule?.comparator} ${max}`);
         } else if (['!='].includes(rule?.comparator)) {
            calcResult = evaluate(`${kpiValuesSum} < ${min}`) || evaluate(`${kpiValuesSum} > ${max}`);
         } else if (['=='].includes(rule?.comparator)) {
            calcResult = evaluate(`${min} <= ${kpiValuesSum} <= ${max}`);
         }

         sumOfValidations.push(calcResult);
         return {
            ...rule,
            min,
            max,
         };
      });
      return sumOfValidations.map((ruleResult, index) => (ruleResult ? undefined : rules[index])).filter((ruleResult) => ruleResult);
   }
   return [];
};

export const getValidationRuleErrors = (intl, validationRuleMessages) => (
   <>
      <Typography variant="inherit">
         {intl.formatMessage({ id: 'validationRule.errorMessages.headline', defaultMessage: 'Validation rules apply:' })}
      </Typography>
      {(validationRuleMessages ?? []).map((rule) => (
         <Typography variant="inherit" key={rule?.id}>
            {intl.formatMessage(
               {
                  id: `validationRule.errorMessages.${rule?.comparator}`,
                  defaultMessage: 'Validation Rule do not fit for the field value.',
               },
               {
                  maxValue: rule?.max,
                  minValue: rule?.min,
                  toleranceAddition: intl.formatMessage(
                     {
                        id: `validationRule.errorMessages.${rule?.tolerance}`,
                        defaultMessage: rule?.tolerance === 'NONE' ? ' ' : ` (including tolerance of {toleranceValue})`,
                     },
                     {
                        toleranceValue: rule?.toleranceValue,
                     }
                  ),
               }
            )}
         </Typography>
      ))}
   </>
);
